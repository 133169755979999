/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


        $('.navbar-fixed-top').affix({
          offset: {
            top: 100,
          },
        });




				/**
				 * WPSL more info btn interactions
				 */
				$(document).on('click', '.btn-class-more-info', function(e) {
					var blockToShow = $(this).attr('href');

					$('.class-more-info-box').hide();
					$(blockToShow).slideDown();
					$("html,body").stop().animate({ scrollTop: $("#wpsl-stores").offset().top - 100 }, 650, 'swing');

					e.preventDefault();
				});






				function filterClassesData() {

					if ( 0 < jQuery('.populate-classes select').length ) {

						var selectionValue = jQuery('.populate-classes select').val(), // make conditional if val !== empty
							selectionSplit, selectedVenueID, selectedTermID;

							if ( 0 < selectionValue.length ) {
								selectionSplit 	 = selectionValue.split('_');
								selectedVenueID  = selectionSplit[0].replace(/\D/g,'');
								selectedTermID 	 = selectionSplit[1].replace(/\D/g,'');
								// selectedClass		 = holidayClubs[selectedVenueID][selectedTermID];
							}

						jQuery('.populate-classes select').on('change', function() {
							selectionValue 	= jQuery(this).val();

							if ( 0 < selectionValue.length ) {
								selectionSplit 	= selectionValue.split('_');
								selectedVenueID = selectionSplit[0].replace(/\D/g,'');
								selectedTermID 	= selectionSplit[1].replace(/\D/g,'');
								// selectedClass		= holidayClubs[selectedVenueID][selectedTermID];

								window.location.href = '../booking/?class_id=' + selectedVenueID + '&term_id=' + selectedTermID;
							}
						});
					}
				}

				filterClassesData();


				/**
				 * Booking form tweaks
				 */
				jQuery(document).bind('gform_post_render', function() {

					jQuery('.ptm-week-calendar .gfield_checkbox input').each( function() {

						inputID = jQuery(this).attr('id');
						if ( jQuery('label[for=' + inputID + ']').text() === 'All Days - £0' ) {
							jQuery(this).prop('disabled', true);
							jQuery(this).attr('readonly', 'readonly');
						}
					});


					filterClassesData();



					jQuery('.ptm-week-calendar .gfield_checkbox input').on('change', function() {
						var parentUL = jQuery(this).parent().parent().attr('id'),
							inputValue = jQuery(this).attr('value');

						if ( 'All Days' === inputValue ) {
							if ( jQuery(this).is(':checked') ) {
								jQuery('#' + parentUL + ' input').not(this).each( function () {
									if ( jQuery(this).is(':checked') ) {
										jQuery(this).trigger('click');
									}
								});
							}
						}
						else {
							if ( jQuery('#' + parentUL + ' li:first-child input').is(':checked') ) {
								jQuery('#' + parentUL + ' li:first-child input').trigger('click');
							}





							var currentQuantity = jQuery('.single_day_prod .ginput_quantity').attr('value');
							if ( currentQuantity.length < 1 ) {
								currentQuantity = 0;
							}

							if ( jQuery(this).is(':checked') ) {
								currentQuantity = parseInt(currentQuantity) + 1;

								jQuery('.single_day_prod .ginput_quantity').attr('value', currentQuantity).trigger('change');
							}
							else {
								currentQuantity = parseInt(currentQuantity) - 1;

								if ( 0 > currentQuantity ) {
									currentQuantity = 0;
								}

								jQuery('.single_day_prod .ginput_quantity').attr('value', currentQuantity).trigger('change');
							}
						}
					});
				});






        /**
         * Supplier Location - ACF Google Map
         *
         * source: https://www.advancedcustomfields.com/resources/google-map/
         */

        /*
        *  add_marker
        *
        *  This function will add a marker to the selected Google Map
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 4.3.0
        *
        *  @param $marker (jQuery element)
        *  @param map (Google Map object)
        *  @return  n/a
        */

        var mapStyle = [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ];


        function add_marker( $marker, map ) {

          // var
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

          // create marker
          var marker = new google.maps.Marker({
            position : latlng,
            map : map,
            optimized: false,
            zIndex:99999999,
            icon: {
              //URL of the image
              url: '../wp-content/themes/littlesuperstars/dist/images/superstars_icon.png',

              //Sets the image size
              size: new google.maps.Size(32,32),

              //Sets the origin of the image (top left)
              origin: new google.maps.Point(0,0),

              //Sets the anchor (middle, bottom)
              anchor: new google.maps.Point(0,32),

              //Scales the image
              scaledSize: new google.maps.Size(32,32)
            }
          });

          // add to array
          map.markers.push( marker );

          // if marker contains HTML, add it to an infoWindow
          if( $marker.html() ) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content : $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
              infowindow.open( map, marker );
            });
          }
        }


        /*
        *  center_map
        *
        *  This function will center the map, showing all markers attached to this map
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 4.3.0
        *
        *  @param map (Google Map object)
        *  @return  n/a
        */

        function center_map( map ) {
          // vars
          var bounds = new google.maps.LatLngBounds();

          // loop through all markers and create bounds
          $.each( map.markers, function( i, marker ) {
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          });

          // only 1 marker?
          if( map.markers.length === 1 ) {
            // set center of map
              map.setCenter( bounds.getCenter() );
              map.setZoom( 16 );
          }
          else {
            // fit to bounds
            map.fitBounds( bounds );
          }
        }




       /**
        * new_map
        *
        * This function will render a Google Map onto the selected jQuery element
        *
        * @type function
        * @date 8/11/2013
        * @since  4.3.0
        *
        * @param  $el (jQuery element)
        * @return n/a
        */

        function new_map( $el ) {
          // var
          var $markers = $el.find('.marker'),
            args = {
              zoom :      16,
              scrollwheel: false,
              center :    new google.maps.LatLng(0, 0),
              mapTypeId : google.maps.MapTypeId.ROADMAP,
              styles: mapStyle
            },
            map = new google.maps.Map( $el[0], args);

          // add a markers reference
          map.markers = [];

          // add markers
          $markers.each(function () {
            add_marker( $(this), map );
          });

          // center map
          center_map( map );

          // return
          return map;
        }



        $('.acf-map').each(function () {
          // create map
          var map = new_map( $(this) );
        });

        /** End Google Map ***********/


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


        /* Init slider */
        $(".rslides").responsiveSlides({
            speed: 800,
            timeout: 7000,
            auto: true,
            pager: true
        });

        $('.rslides_tabs a').each(function() {
            if ($(this).text() != null) {
                $(this).text('');
            }
        });

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // // booking appointment
  // $(".popup").toggle( function() {
  //    $('.form-content').toggle("slow");
  //    $('.book-app-form').addClass('popup-form-bg');
  //    $(this).addClass('popup-header');
  //    }, function () {
  //    $('.form-content').toggle("slow");
  //    $('.book-app-form').removeClass('popup-form-bg');
  //    $(this).removeClass('popup-header');
  // });

  // // fix nav toggle
  // $('.navbar-toggle').on('click', function() {
  //   $('.navbar-toggle').toggleClass("nav-toggle-fix");
  // });

  // $('#inner-split-slider-carousel .carousel').carousel({
  //   interval: 300
  // });

  // $('.view-more-success').on('click', function() {
  //   $('.hide-tab-btns').fadeIn("slow").css('display', 'inline-block');
  //   $('.view-more-success').fadeOut("slow");
  // });

  /* Show about submenu items */
  $('#menu-main-nav .about-submenu > a').on('click', function (e) {
    e.stopPropagation();
      if ($(this).next('ul').hasClass('show')) {
          $('.sub-menu').removeClass('show');
          $(this).removeClass('rev-arrow');
      } else {
          $('.sub-menu').removeClass('show');
          $(this).next('ul').toggleClass('show');
          $('#menu-main-nav a').removeClass('rev-arrow');
          $(this).addClass('rev-arrow');
      }
  });
  /* Show Fixed about submenu items */
  $('#menu-main-nav-1 .about-submenu > a').on('click', function (e) {
    e.stopPropagation();
      if ($(this).next('ul').hasClass('show')) {
          $('.sub-menu').removeClass('show');
          $(this).removeClass('rev-arrow');
      } else {
          $('.sub-menu').removeClass('show');
          $(this).next('ul').toggleClass('show');
          $('#menu-main-nav-1 a').removeClass('rev-arrow');
          $(this).addClass('rev-arrow');
      }
  });

  /* Show Clubs submenu items */
  $('#menu-main-nav .clubs-submenu > a').on('click', function (e) {
    e.stopPropagation();
      if ($(this).next('ul').hasClass('show')) {
          $('.sub-menu').removeClass('show');
          $(this).removeClass('rev-arrow');
      } else {
          $('.sub-menu').removeClass('show');
          $(this).next('ul').toggleClass('show');
          $('#menu-main-nav a').removeClass('rev-arrow');
          $(this).addClass('rev-arrow');
      }
  });
  /* Show Fixed Clubs submenu items */
  $('#menu-main-nav-1 .clubs-submenu > a').on('click', function (e) {
    e.stopPropagation();
      if ($(this).next('ul').hasClass('show')) {
          $('.sub-menu').removeClass('show');
          $(this).removeClass('rev-arrow');
      } else {
          $('.sub-menu').removeClass('show');
          $(this).next('ul').toggleClass('show');
          $('#menu-main-nav-1 a').removeClass('rev-arrow');
          $(this).addClass('rev-arrow');
      }
  });

  /* Show Clubs sub-submenu items */
  $('#menu-main-nav .clubs-submenu .sub-menu .menu-item-has-children > a').on('click', function (e) {
    e.stopPropagation();
      if ($(this).next('ul').hasClass('show')) {
          $(this).next('ul').removeClass('show');
          $(this).removeClass('rev-arrow');
      } else {
          $('#menu-main-nav .clubs-submenu .sub-menu .menu-item-has-children > ul').removeClass('show');
          $(this).next('ul').toggleClass('show');
          $('.clubs-submenu .sub-menu a').removeClass('rev-arrow');
          $(this).addClass('rev-arrow');
      }
  });
  /* Show Fixed Clubs sub-submenu items */
  $('#menu-main-nav-1 .clubs-submenu .sub-menu .menu-item-has-children > a').on('click', function (e) {
    e.stopPropagation();
      if ($(this).next('ul').hasClass('show')) {
          $(this).next('ul').removeClass('show');
          $(this).removeClass('rev-arrow');
      } else {
          $('#menu-main-nav-1 .clubs-submenu .sub-menu .menu-item-has-children > ul').removeClass('show');
          $(this).next('ul').toggleClass('show');
          $('.clubs-submenu .sub-menu a').removeClass('rev-arrow');
          $(this).addClass('rev-arrow');
      }
  });

  /* Close all menus **********/
  $(document).click(function(){
    $('.sub-menu').removeClass('show');
    $('a').removeClass('rev-arrow');
  });

  // fix nav toggle
  // var mainToTop = true;
  $('.navbar-toggle').on('click', function (e) {
    e.preventDefault();
    $('.navbar-toggle').toggleClass("nav-toggle-fix");
    $('.navbar-toggle .nav-icon').toggleClass('open');
    // $('.banner').fadeToggle();
    // $('.main').animate({
  //       marginTop: (!mainToTop)*120+'px'
  //   }, 300);
  //   mainToTop = !mainToTop;
  });

})(jQuery); // Fully reference jQuery after this point.
